<template>
  <div>
    <div class="mx-auto max-w-xs text-center">
      <NuxtLink to="/">
        <img class="inline-block h-10 w-10 align-top" src="/ag-avatar.svg" alt="Approach Guides" />
      </NuxtLink>
      <div v-if="display === 'form'">
        <h1 class="mt-8 text-2xl font-medium text-heading">Sign in to your account</h1>
        <p class="mt-2">Enter your email and we'll send a link that logs you in automagically.</p>
      </div>
    </div>
    <LoginOrRegisterForm
      action="login"
      class="mt-12"
      :redirect-to="redirectTo"
      :source="signinSource"
      :collection-hashid="signinCollectionHashid"
      :partner-slug="signinPartner"
      @success="onSuccess"
    />
    <p class="mx-12 mt-8 text-center text-sm text-supplement">
      Don't have an account?
      <NuxtLink to="/signup">Sign up</NuxtLink>
    </p>
  </div>
</template>

<script setup lang="ts">
import type { LoginOrRegisterResponse } from "~/components/forms/LoginOrRegisterForm.vue";
import type { SignupSource } from "~/composables/useSignup";

definePageMeta({
  layout: "auth-page",
  middleware: ["guest"],
});

const route = useRoute();

// Redirect
const { redirect } = route.query;
const redirectTo = (typeof redirect === "string" && redirect) || "/experiences";

// Validate and pass signup source data
const { isValidSource } = useSignup();
const source = route.query.source as SignupSource;
const signinSource = isValidSource(source) ? source : undefined;
const signinCollectionHashid = isValidSource(source) && typeof route.query["collection"] === "string" ? route.query["collection"] : undefined;
const signinPartner = isValidSource(source) && typeof route.query.partner === "string" ? route.query.partner : undefined;

const display = ref<"form" | "new_user" | "existing_user">("form");
const onSuccess = ({ new_user }: LoginOrRegisterResponse) => (display.value = new_user ? "new_user" : "existing_user");

useHead({
  title: "Login",
});
</script>
